import React from "react"
import "../styles/main.scss"
import Header from "./header";
import Footer from "./footer";

export default function Layout({children}) {
  return (
    <div className="site">
      <Header/>
      <div className="site-content">
        {children}
      </div>
      <Footer/>
    </div>
  )
}
