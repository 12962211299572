import React from "react"
import Layout from "../components/layout";

export default function Impressum() {
  return (
    <Layout>
      <h2>Impressum</h2>
      <article>
        <p>
          <strong>Angaben gemäß § 5 TMG:</strong>
          <br/>
          <span className="obf">(tknärhcsebsgnutfah) GU latigid.relug</span>
          <br/>
          <span className="obf">32 edlefnebuaT mA</span>
          <br/>
          <span className="obf">revonnaH 95103</span>
        </p>
        <p>
          <strong>Registereintrag:</strong>
          <br/>
          Eintragung im Handelsregister.
          <br/>
          Amtsgericht: <span className="obf">revonnaH</span>
          <br/>
          Registernummer: <span className="obf">475322 BRH</span>
        </p>
        <p>
          <strong>Vertreten durch:</strong>
          <br/>
          guler.digital UG (haftungsbeschränkt), vertreten durch den
          Geschäftsführer <span className="obf">relüG naneK</span>
        </p>
        <p>
          <strong>Kontakt:</strong>
          <br/>
          E-Mail: <span className="obf">latigid.relug@tsop</span>
        </p>
        <p>
          <strong>Umsatzsteuer:</strong>
          <br/>
          Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:&nbsp;
          <span className="obf">847612253ED</span>
        </p>
        <p>
          <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</strong>
          <br/>
          <span className="obf">relüG naneK</span>
          <br/>
          <span className="obf">32 edlefnebuaT mA</span>
          <br/>
          <span className="obf">revonnaH 95103</span>
        </p>
        <p>
          <strong>Streitschlichtung</strong>
          <br/>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </p>
      </article>
      <hr/>
      <article>
        <h2>Haftungsausschluss</h2>
        <p>
          <strong>Haftung für Inhalte</strong>
          <br/>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
          übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
          eine rechtswidrige Tätigkeit hinweisen.
          <br/>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
          bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
          konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
        <p>
          <strong>Urheberrecht</strong>
          <br/>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
          Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
          des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
          Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          <br/>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
          beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
          Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
      </article>
    </Layout>
  );
}
