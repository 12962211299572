import React from "react"
import {Link} from "gatsby";

export default function Header() {
  return (
    <div className="gd-top">
      <div className="gd-bar gd-white gd-wide gd-padding gd-card">
        <a href="/" className="gd-bar-item gd-button"><b>guler.digital</b> UG
          (haftungsbeschränkt)</a>
        <div className="gd-right gd-hide-small">
          <Link to="/" className="gd-bar-item gd-button"
                activeClassName="active">Startseite</Link>
          <Link to="/impressum" className="gd-bar-item gd-button"
                activeClassName="active">Impressum</Link>
        </div>
      </div>
    </div>
  )
}
